@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Raleway:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fira+Sans+Extra+Condensed:wght@200;400;500&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: black;
}
